import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonResponse } from '@app/_models';
import { environment } from '@environments/environment';
import { map } from 'rxjs';
import { HeaderConstants } from '@app/constant/headers';

@Injectable({
  providedIn: 'root',
})
export class ItineraryService {
  itineraryNo?: number;
  branchCode?: string;

  constructor(private http: HttpClient, private hConst: HeaderConstants) {
    // itineraryNo = Number(sessionStorage.getItem('itineraryNo'));
    // itineraryNo = 900001093;
    const userDataString = sessionStorage.getItem('user');
    let userData = userDataString ? JSON.parse(userDataString) : null;
    // itineraryNo = userData?.itineraryNo;
  }

  setCurrentitineraryno() {
    // sessionStorage.setItem('itineraryNo', itineraryNo.toString());
    // itineraryNo = itineraryNo;

    const userDataString = sessionStorage.getItem('user');
    let userData = userDataString ? JSON.parse(userDataString) : null;
    // itineraryNo = userData?.itineraryNo;
  }

  getTravelExpertsdDetails(itineraryNo) {
    //const tempItineraryNumber = 900001093;
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.travelExpertDetails}/${itineraryNo}`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getCorrespondenceDetails(itineraryNo) {
    // const tempItineraryNumber = 900001177;
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.correspondenceDetails}/${itineraryNo}`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getitineraryDetailsDetails(itineraryNo?: any, withCpntImageFlag?: any) {

    if (!withCpntImageFlag) {
      withCpntImageFlag = false;
    }
    // const itineraryNo = 900001093;
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.itineraryDetails}/${itineraryNo}?withCpntImage=` + withCpntImageFlag,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getMenuList(itineraryNo, branchCode) {
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.menuList}/${itineraryNo}?BranchCode=${branchCode}`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getItineraryDetails(itineraryNo: any, withCpntImageFlag?: any) {

    if (!withCpntImageFlag) {
      withCpntImageFlag = false;
    }

    // const tempItineraryNumber = 900001093;
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.itinerarydetails}/${itineraryNo}?withCpntImageasdasd=` + withCpntImageFlag,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAccommodationDetails(itineraryNo) {
    // const tempItineraryNo: any = '900001093';
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.accomodationdetails}/${itineraryNo}`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPriceDetails(itineraryNo) {
    // const tempItineraryNo: any = '900001093';
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.pricedetails}/${itineraryNo}`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getItineraryFlightDetails(itineraryNo) {
    // const tempItineraryNo: any = '900001093';    
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.flightdetails}/${itineraryNo}`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          // console.log(response);
          return response;
        })
      );
  }

  getEssentialinfo(itineraryNo) {
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.essentialinfo}/${itineraryNo}`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getTravelOverviewMenu(itineraryNo, branchCode) {
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.travelOverview}/${itineraryNo}?BranchCode=${branchCode}`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getComplaintsinfo(itineraryNo) {
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.complaints}/${itineraryNo}`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getTermsAndCondition(itineraryNo) {
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.termsAndCondition}/${itineraryNo}`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getTravelAdviceData(itineraryNo) {
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.travelAdvice}/${itineraryNo}`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getItineraryMenuList(itineraryNo) {
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.menulist}/${itineraryNo}`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getMapDetails(itineraryNo) {
    return this.http
      .get<commonResponse>(
        `${environment.apiUrl}/${environment.mapdetails}/${itineraryNo}`,
        { headers: this.hConst.getHeaders() }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
