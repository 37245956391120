import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  // loadScripts(scripts: string[]): void {
  //   scripts.forEach(scriptSrc => {
  //     this.loadScript(scriptSrc);
  //   });
  // }

  // loadScript(src: string): void {
  //   const script = this.renderer.createElement('script');
  //   script.src = src;
  //   script.type = 'text/javascript';
  //   script.async = true;
  //   this.renderer.appendChild(document.body, script);
  // }

  // removeScripts(scripts: string[]): void {
  //   scripts.forEach(scriptSrc => {
  //     this.removeScript(scriptSrc);
  //   });
  // }

  // removeScript(src: string): void {
  //   const scripts = document.querySelectorAll(`script[src="${src}"]`);
  //   scripts.forEach(script => {
  //     if (script.parentNode) {
  //       script.parentNode.removeChild(script);
  //     }
  //   });
  // }
}
