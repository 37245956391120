export class Price {
  priceDetailList?: PriceDetailList[];
  totalPrice?: number;
  currencySymbol?: string;
  paymentReceived?: number;
  balance?: number;
  balanceDueDate?: string;
  includes?: string;
  excludes?: string;
  deposit?: number;
  bookingNo?: any;
  optionalList: optionalList[];
  amount?: number;
  priceValidDate?: string;
  noOfPerson?: number;
  onlineBookingFormUrl?: any;
  quoteNo?: any;
}

export class PriceDetailList {
  heading?: string;
  amount?: number;
  shortDesc?: string;
  longDesc?: string;
  currencySymbol?: string;
}

export class optionalList {
  heading?: string;
  amount?: number;
  shortDesc?: string;
  longDesc?: string;
  currencySymbol?: string;
}
