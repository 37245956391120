import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router) { }

  logout() {

    const user = JSON.parse(sessionStorage.getItem('user')!) || [];
    let branchCode = user?.branchCode;

    if (!branchCode) {
      branchCode = 'DN';
    }

    sessionStorage.removeItem('dictionary');
    if (branchCode) {
      sessionStorage.clear();
      sessionStorage.clear();
    }

    // Update the userSubject to null
    // this.userSubject.next(null);

    // Navigate to login page
    this.router.navigate(['/account/login/' + branchCode]);


    // Old code
    // localStorage.removeItem('token');  // Or wherever the token is stored
    // // Redirect to the login page
    // window.location.href = '/login';
  }

  // Other methods for login, refresh token, etc.
}
