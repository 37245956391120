// pdf.service.ts

import { Injectable } from '@angular/core';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

@Injectable({
    providedIn: 'root'
})
export class PdfService {

    constructor() { }

    options: any = {
        orientation: 'portrait', // or 'landscape'
        unit: 'mm',
        format: 'letter', // specify page size (e.g., 'a4', 'letter', etc.)
    }

    // generatePdf(element: HTMLElement, fileName: string) {
    //     html2canvas(element).then(canvas => {
    //         const imgData = canvas.toDataURL('image/jpg');
    //         const pdf = new jsPDF(this.options);
    //         const imgProps = pdf.getImageProperties(imgData);
    //         const pdfWidth = pdf.internal.pageSize.getWidth();
    //         const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //         pdf.addImage(imgData, 'JPG', 0, 0, pdfWidth, pdfHeight);
    //         pdf.save(fileName + '.pdf');
    //     });
    // }

    // generatePdf(pages: HTMLElement[], fileName: string) {

    //     // Set options for html2canvas
    //     const options = {
    //         scale: 2, // Increase the scale for higher resolution
    //         useCORS: true, // Enable CORS to capture images from external domains
    //         logging: true // Enable logging to see if there are any errors
    //     };

    //     console.log('pages', pages);
    //     const doc = new jsPDF();

    //     // Iterate over each page
    //     pages.forEach((page, index) => {
    //         // Add a new page for all pages except the first one
    //         if (index !== 0) {
    //             doc.addPage();
    //         }

    //         // Convert HTML content to canvas
    //         html2canvas(page, options).then(canvas => {
    //         //     // Convert canvas to base64 image data
    //         //     // const imgData = canvas.toDataURL('image/png');

    //         //     // Add image to PDF
    //         //     doc.addImage(imgData, 'PNG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());

    //         //     // If this is not the last page, go to the next page
    //         //     if (index !== pages.length - 1) {
    //         //         doc.addPage();
    //         //     } else {
    //         //         // Save the PDF once all pages have been added
    //         //         doc.save(fileName + '.pdf');
    //         //     }


    //             const imgData = canvas.toDataURL('image/jpg');
    //             // const pdf = new jsPDF(this.options);
    //             const imgProps = doc.getImageProperties(imgData);
    //             const pdfWidth = doc.internal.pageSize.getWidth();
    //             const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //             doc.addImage(imgData, 'JPG', 0, 0, pdfWidth, pdfHeight);

    //             // If this is not the last page, go to the next page
    //             if (index !== pages.length - 1) {
    //                 doc.addPage();
    //             } else {
    //                 // Save the PDF once all pages have been added
    //                 doc.save(fileName + '.pdf');
    //             }

    //         });
    //     });
    // }
}
