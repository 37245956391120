import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextReplacementService {

  constructor() { }

  replaceTextWithDictionary(dictionary: { [key: string]: string }) {
    const walker = document.createTreeWalker(
      document.body,
      NodeFilter.SHOW_TEXT,
      null,
      // false
    );

    let node: Text | null;
    while (node = walker.nextNode() as Text | null) {
      for (const [key, value] of Object.entries(dictionary)) {
        const regex = new RegExp(`\\b${key}\\b`, 'g');
        node.textContent = node.textContent?.replace(regex, value) || '';
      }
    }
  }
}
