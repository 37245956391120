// html-to-pdf.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HtmlToPdfService {

  constructor(private http: HttpClient) { }

  getExternalHtmlContent(url: string) {
    return this.http.get(url, { responseType: 'text' });
  }
}
