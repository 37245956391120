import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private callFunctionSource = new Subject<void>();
  callFunction$ = this.callFunctionSource.asObservable();

  triggerCallFunction() {
    this.callFunctionSource.next();
  }
}
