import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountService, DashboardService, ItineraryService } from '@app/_services';
import { DictionaryService } from '@app/_services/dictionary.service';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap, first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    user: any;
    branchCode: any;

    constructor(
        private router: Router,
        private accountService: AccountService,
        private dashboardService: DashboardService,
        private itineraryService: ItineraryService,
        private dictionaryService: DictionaryService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this.user = JSON.parse(sessionStorage.getItem('user')!) || {};
        if (route.queryParams['userid'] && route.queryParams['accountcode']) {
            return this.getAuthToken().pipe(
                switchMap((token: any) => {
                    if (token) {
                        this.user['token'] = token;
                        this.user['userId'] = route.queryParams['userid'];
                        this.user['accountCode'] = route.queryParams['accountcode'];
                        this.user['itineraryNo'] = route.queryParams['itineraryno'] || null;
                        this.user['branchCode'] = route.queryParams['branchCode'] || null;

                        sessionStorage.setItem('user', JSON.stringify(this.user));

                        if (this.user['branchCode']) {
                            this.getDictionaryDetails(this.user['branchCode']);
                        } else {
                            this.getDashboardDetails(this.user['accountCode'], this.user['itineraryNo']);
                        }

                        return of(true);
                    } else {
                        // console.log("No token received");
                        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
                        return of(false);
                    }
                }),
                catchError((error) => {
                    console.error("Error retrieving token:", error);
                    this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
                    return of(false);
                })
            );
        } else if (this.user && this.user['branchCode']) {
            this.getDictionaryDetails(this.user['branchCode']);
            return true;
        } else {
            if (Object.keys(this.user).length !== 0) {
                return of(true);
            }
            this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }

    getAuthToken(): Observable<any> {
        return this.accountService.getAuthtoken().pipe(
            map((response: any) => response ? response : false),
            catchError((error) => {
                console.error(error);
                return of(false);
            })
        );
    }

    getDashboardDetails(accountCode: any, itineraryNo: any): void {
        if (accountCode) {
            this.dashboardService.getDashboardDetails(accountCode, itineraryNo).pipe(first()).subscribe({
                next: (response) => {
                    if (response.isSuccess) {
                        // console.log('response.responseData', response.responseData['client']);
                        // const client = response.responseData['client'];
                        // this.user = Object.assign({}, this.user, client);
                        // console.log('user', this.user);
                        this.getDictionaryDetails(response?.responseData?.itineraryDetails?.branchCode);
                    }
                },
                error: (error) => {
                    // console.log('error333');
                    console.error("Error retrieving dashboard details:", error);
                },
            });
        }
    }

    getDictionaryDetails(branchCode: string): void {
        this.dictionaryService.getDictionaryDetails().pipe(first()).subscribe({
            next: (response: any) => {
                if (response.isSuccess) {
                    const result = this.extractKeyValuePairs(response?.responseData, 'word', this.getTranslationKey(branchCode));
                    sessionStorage.setItem('dictionary', JSON.stringify(result));
                }
            },
            error: (error) => {
                console.error("Error retrieving dictionary details:", error);
            },
        });
    }

    extractKeyValuePairs(array: any[], keyProperty: string, valueProperty: string): any {
        return array.reduce((result, item) => {
            result[item[keyProperty]] = item[valueProperty];
            return result;
        }, {});
    }

    getTranslationKey(branchCode: string): string {
        const branchTranslations = {
            'US': 'translation_US',
            'UK': 'translation_UK',
            'Asia': 'translation_Asia',
            'India': 'translation_India',
            'NO': 'translation_NO',
            'DK': 'translation_DK'
        };
        return branchTranslations[branchCode] || 'translation_DK';
    }
}