import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
// import { fakeBackendProvider } from './_helpers';
import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AppComponent } from './app.component';
import { HomeComponent } from './home';
import { LoaderInterceptor } from '@app/_components/loader/loader.interceptor';
import { environment } from '@environments/environment';
import { CommonModule, DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';

// Global region date changes
import { registerLocaleData } from '@angular/common';

// import localeEnUS from '@angular/common/locales/es-US';
// import localeEnIN from '@angular/common/locales/en-IN';

import localeEn from '@angular/common/locales/en';
// import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeNo from '@angular/common/locales/nb';
// Import other locales as needed

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CorsInterceptor } from '@shared/http-interceptor';
// import { SharedModule } from '@shared/shared.module';
import { AccountService, LocaleService } from '@app/_services';
// import { TranslationModule } from './translation.module';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return 'Translation not found!';
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { CarouselModule } from 'ngx-owl-carousel-o';
// Import the library
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { MatDialogModule } from '@angular/material/dialog';

// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { TokenInterceptor } from './_helpers/token.interceptor';
import { AuthInterceptor } from './_helpers/auth.interceptor';

// import { license } from '@progress/kendo-licensing';
// license.setLicenseKey('your-license-key');
// registerLocaleData(localeEnIN); // Register French locale data
registerLocaleData(localeEn, 'en');
// registerLocaleData(localeFr, 'fr');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeNo, 'nb');
// Register other locales as needed

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    HttpClientModule,
    MatDialogModule,
    // SharedModule,
    HttpClientModule,
    NgxSpinnerModule,
    CarouselModule,
    NgxImageZoomModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler
      }
    }),
    AppRoutingModule,
  ],
  declarations: [
    AppComponent,
    HomeComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: 'googleMapsApiKey', useValue: environment.googleMapsApiKey },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CorsInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: LOCALE_ID,
      deps: [LocaleService],
      useFactory: (localeService: LocaleService) => localeService.getLocale()
    },
    // { provide: LOCALE_ID, useValue: 'en' }, // default locale
    // { provide: LOCALE_ID, useValue: 'de' },
    // { provide: LOCALE_ID, useValue: 'nb' },
    DatePipe, // Optional: DatePipe is automatically provided by CommonModule
    AccountService,
    // provider used to create fake backend
    // fakeBackendProvider
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA], // Add CUSTOM_ELEMENTS_SCHEMA here
  bootstrap: [AppComponent]
})

export class AppModule { };