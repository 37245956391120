import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { commonResponse } from '@app/_models';
import { HeaderConstants } from '@app/constant/headers';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AccountService {
  public userSubject: BehaviorSubject<User | null>;
  public user: Observable<User | null>;
  private userData: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public data: Observable<string> = this.userData.asObservable();
  public userid: any | undefined;
  public accountcode: any | undefined;

  constructor(private router: Router, private http: HttpClient) {
    // new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('user')!));
    this.userSubject = new BehaviorSubject<any>(
      JSON.parse(sessionStorage.getItem('user')!)
    );
    if (this.userSubject) {
      this.user = this.userSubject.asObservable();
      this.userid = this.userSubject.value?.userId;
      this.accountcode = this.userSubject.value?.accountCode;
      this.userData.next(this.userid);
    }
  }

  public get userValue(): any {
    return this.userSubject.value;
  }

  getAuthtoken(): Observable<string> {
    return this.http.get(`${environment.apiUrl}/${environment.authToken}`, { responseType: 'text' });
  }

  login(email: any, password: any, branchCode: any): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/${environment.login}`, {
        email,
        password,
        branchCode
      })
      .pipe(
        map((response) => {
          // sessionStorage.setItem('user', JSON.stringify(user));
          // this.userSubject.next(user);
          if (response.responseData) {
            const user = response?.responseData;
            user.password = password;
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem('user', JSON.stringify(user));
            this.userSubject.next(user);
            return user;
          } else {
            return response;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          // Handle the error
          return this.handleError(error);
        })
      )
  }

  erroflag: boolean = false;
  private handleError(error: HttpErrorResponse) {
    // Log the full error response for debugging
    // console.error('Full error response:', error);

    const erroflag: boolean = false;
    let errorMessage: any = '';
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Client-side error: ${error.error.message}`;
      this.erroflag = true;
    } else {
      // Server-side error
      if (error.status && error.error) {
        errorMessage = `Server returned code: ${error.status}, body was: ${JSON.stringify(error.error)}`;
      } else {
        errorMessage = error;
      }
      this.erroflag = true;
    }

    if (!erroflag) {
      errorMessage = error;
    }

    // Log the full error response for debugging
    // console.error('Full errorMessage response:', errorMessage);

    // Return a more detailed error object or message
    return throwError(() => new Error(errorMessage));
  }

  logout() {
    // remove user from local storage and set current user to null
    // Clear all sessionStorage and sessionStorage
    const user = JSON.parse(sessionStorage.getItem('user')!) || [];
    let branchCode = user?.branchCode;

    if (!branchCode) {
      branchCode = 'DN';
    }

    sessionStorage.removeItem('dictionary');
    if (branchCode) {
      sessionStorage.clear();
      sessionStorage.clear();
    }

    // Update the userSubject to null
    this.userSubject.next(null);

    // Navigate to login page
    this.router.navigate(['/account/login/' + branchCode]);
  }

  // resetpassword(user: User) {
  // return this.http.post(`${environment.apiUrl}/users/resetpassword`, user);
  // }

  forgotpassword(email: any, branchCode: any) {
    return this.http
      .post<any>(`${environment.apiUrl}/${environment.forgotpassword}/forgotpassword`, {
        email,
        branchCode
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          // console.log('error22', error);
          // Handle the error
          return this.handleError(error);
        })
      )

    // return this.http.post(
    //   `${environment.apiUrl}/${environment.forgotpassword}/forgotpassword`,
    //   user
    // );
  }

  changepassword(user: User) {
    return this.http
      .post(`${environment.apiUrl}/users/changepassword`, {
        user
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          sessionStorage.setItem('user', JSON.stringify(user));
          this.userSubject.next(user);
          return user;
        }),
        catchError((error: HttpErrorResponse) => {
          // Handle the error
          return this.handleError(error);
        })
      );;
  }

  resetpassword(resetDetails: any) {
    return this.http
      .post(
        `${environment.apiUrl}/${environment.resetpassword}`,
        resetDetails
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          // Handle the error
          return this.handleError(error);
        }),
      );
  }

  profilechangepassword(profileDetails: any) {
    return this.http
      .post(
        `${environment.apiUrl}/${environment.profilechangepassword}`,
        profileDetails
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          // Handle the error
          return this.handleError(error);
        })
      );
  }

  register(user: User) {
    return this.http.post(`${environment.apiUrl}/users/register`, user);
  }

  getAll() {
    return this.http.get<User[]>(`${environment.apiUrl}/users`);
  }

  getById(id: string) {
    return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  update(id: any, params: any) {
    return this.http.put(`${environment.apiUrl}/users/${id}`, params).pipe(
      map((x) => {
        // update stored user if the logged in user updated their own record
        if (id == this.userValue.id) {
          // update local storage
          const user = { ...this.userValue, ...params };
          sessionStorage.setItem('user', JSON.stringify(user));

          // publish updated user to subscribers
          this.userSubject.next(user);
        }
        return x;
      })
    );
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/users/${id}`).pipe(
      map((x) => {
        // auto logout if the logged in user deleted their own record
        if (id == this.userValue.id) {
          this.logout();
        }
        return x;
      })
    );
  }

  fetchData() {
    return this.http.get<any>(
      'https://cms-api.excelleresolutions.com/api/destinations?filters[main_page_ind][$eq]=true&fields[0]=destination_code&fields[1]=destination_name&populate[destination_images][fields][2]=image_path&populate[destination_images][fields][3]=image_type&populate[countries][fields][4]=country_code&populate[countries][fields][5]=country_name&fields[6]=main_page_ind&fields[7]=main_page_serial_number'
    );
  }
}
