<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Please wait while loading PDF...</p>
</ngx-spinner>
<!-- main app container -->
<div class="app-container">
  <router-outlet></router-outlet>
</div>